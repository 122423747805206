import { useRef, useEffect } from 'react';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle
} from '@/components/ui/dialog';
import { Markdown } from '@/components/generic/markdown';
import { useAuthInfo } from '@propelauth/react';
import { useQuery } from '@tanstack/react-query';
import { TimedProgress } from '@/components/ui/progress';
import { DDQPairWithEditHistory } from '@/types';
import { useRegion } from '@/lib/use-region';

interface ContextDialogProps {
  isOpen: boolean;
  onClose: () => void;
  ddqId: string;
  pairIdToHighlight?: string;
}

export function ContextDialog({
  isOpen,
  onClose,
  ddqId,
  pairIdToHighlight
}: ContextDialogProps) {
  const highlightedRef = useRef<HTMLDivElement | null>(null);
  const dialogContentRef = useRef<HTMLDivElement | null>(null);
  const { accessToken } = useAuthInfo();

  const { baseApiUrl } = useRegion();

  const { data: ddqData, isLoading } = useQuery({
    queryKey: ['ddqData', ddqId],
    queryFn: async () => {
      const response = await fetch(`${baseApiUrl}/ddq/${ddqId}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      });
      if (!response.ok) {
        throw new Error('Error fetching DDQ data');
      }
      return response.json();
    },
    enabled: isOpen // Only fetch when the dialog is open
  });

  useEffect(() => {
    if (isOpen && ddqData) {
      const images = dialogContentRef.current?.querySelectorAll('img') || [];
      const imageLoadPromises = Array.from(images).map((img) => {
        if (img.complete) return Promise.resolve();
        return new Promise((resolve) => {
          img.onload = img.onerror = resolve;
        });
      });

      Promise.all(imageLoadPromises).then(() => {
        if (highlightedRef.current) {
          highlightedRef.current.scrollIntoView({
            behavior: 'smooth',
            block: 'start'
          });
        }
      });
    }
  }, [ddqData, isOpen]);

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent
        ref={dialogContentRef}
        className='max-w-fit h-screen flex flex-col gap-8'
      >
        <DialogHeader>
          <DialogTitle>{ddqData?.friendly_name}</DialogTitle>
        </DialogHeader>
        <div className='w-7xl overflow-y-scroll flex-grow flex flex-col gap-8'>
          {isLoading ? (
            <div className='m-40 flex-grow w-96 h-full flex items-center justify-center'>
              <TimedProgress durationMs={2500} />
            </div>
          ) : (
            <>
              {ddqData?.pairs.map((pair: DDQPairWithEditHistory) => (
                <div
                  key={pair.id}
                  ref={pair.id === pairIdToHighlight ? highlightedRef : null}
                  className={
                    pair.id === pairIdToHighlight
                      ? 'bg-yellow-100 rounded-md m-auto'
                      : 'm-auto'
                  }
                >
                  <Markdown
                    className='m-4'
                    content={pair.content}
                    ddqId={ddqId}
                  />
                </div>
              ))}
            </>
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
}
