import { Button } from '@/components/ui/button';
import { Separator } from '@/components/ui/separator';

import { ArrowLeftIcon, ArrowRightIcon } from '@radix-ui/react-icons';
import { Markdown } from '@/components/generic/markdown';

import '@/css/display.css';
import { useDDQState } from '../use-ddq-state';
import { captureEvent } from '@/lib/analytics';
import { useParams } from 'react-router-dom';
import { useBlankStatus } from '@/lib/is-blank';
import { MinimalTiptapEditor } from '@/components/minimal-tiptap';
import { useCallback, useRef, useEffect } from 'react';
import { debounce } from 'lodash';
import { Loader2, X } from 'lucide-react';
import { RerenderEditorContentHandle } from '@/components/minimal-tiptap/components/minimal-tiptap';

import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import calendar from 'dayjs/plugin/calendar';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { useAuthInfo } from '@propelauth/react';
import { useEditorState } from '../use-editor-state';
import AnswerColumns from '@/components/generic/answer-columns';
import { ShortcutKey } from '@/components/minimal-tiptap/components/shortcut-key';
import { ignoreWhenFocused, useKeyboardShortcut } from '@/lib/use-keyboard-shortcut';
import Assign from './assign';
import { useProfilesState } from '../use-collaboration-state';
import { useUpdateAssignessQuery } from '@/queries/assignee-requests';

dayjs.extend(relativeTime);
dayjs.extend(calendar);
dayjs.extend(utc);
dayjs.extend(timezone);

function formatFriendlyDate(datetimeString: string) {
  const date = dayjs.utc(datetimeString).local();
  const now = dayjs();

  if (date.isSame(now, 'day')) {
    return date.fromNow();
  } else {
    return date.calendar(null, {
      sameDay: '[today at] h:mm A',
      nextDay: '[tomorrow at] h:mm A',
      nextWeek: 'dddd [at] h:mm A',
      lastDay: '[yesterday at] h:mm A',
      lastWeek: '[last] dddd [at] h:mm A',
      sameElse: 'MMMM D, YYYY [at] h:mm A'
    });
  }
}

function convertUTCToLocal(datetimeString: string) {
  return dayjs.utc(datetimeString).local().format('MMMM D, YYYY h:mm A');
}

function useCurrentUserEmail() {
  const { user } = useAuthInfo();
  const currentUserEmail = user?.email || '';

  return function (email: string) {
    if (email === currentUserEmail) {
      return 'you';
    } else {
      return email.split('@')[0];
    }
  };
}

// eslint-disable-next-line
export const QuestionDisplay = () => {
  const {
    state: [ddqState],
    setSelectedQuestion,
    currentQuestionData,
    updateQuestionData,
    pairObj,
    pairPagination,
    mutateCurrentEditStateWithEdit,
    currentEditState,
    isAnyEditorMutationLoading,
  } = useDDQState();

  const { replacementText, resetReplacementText } = useEditorState();

  const editorRef = useRef<RerenderEditorContentHandle>(null);

  const { profiles } = useProfilesState();

  useEffect(() => {
    if (replacementText.length > 0) {
      editorRef.current?.rerender(replacementText[replacementText.length - 1]);
      resetReplacementText();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [replacementText]);

  const pair = pairObj ? pairObj[0] : null;

  const { current, next, prev, length } = pairPagination;

  const { uuid: ddqId } = useParams();

  const { isBlank } = useBlankStatus();

  const handleEditorUnfocusShortcut = (event: KeyboardEvent) => {
    if (editorRef.current?.isFocused() && event.key === 'Escape') {
      editorRef.current.blur();
    }
  };

  useKeyboardShortcut(handleEditorUnfocusShortcut);

  const handleQuestionNavigationShortcuts = ignoreWhenFocused((event: KeyboardEvent) => {
    if ((event.key === 'n' || event.key === 'ArrowDown' || event.key === 'ArrowRight') && next !== undefined) {
      captureEvent('analysisClickQuestionArrow', {
        doc_id: ddqId || '',
        pair_id: ddqState.selectedQuestion || '',
        direction: 'next'
      });
      setSelectedQuestion(next);
    } else if ((event.key === 'p' || event.key === 'ArrowUp' || event.key === 'ArrowLeft') && prev !== undefined) {
      captureEvent('analysisClickQuestionArrow', {
        doc_id: ddqId || '',
        pair_id: ddqState.selectedQuestion || '',
        direction: 'prev'
      });
      setSelectedQuestion(prev);
    } else if (event.key === 'e' && editorRef.current) {
      editorRef.current.focus();
    }
  });

  useKeyboardShortcut(handleQuestionNavigationShortcuts);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onEditorUpdate = useCallback(
    debounce(
      async (content: string) => {
        if (!pair) return;

        console.log('editor update', pair.id, content);

        updateQuestionData(pair.id, { content });
        mutateCurrentEditStateWithEdit.mutateAsync({ content, id: pair.id });
      },
      2000,
      { maxWait: 15000 }
    ),
    [pair]
  );

  const displayCurrentUser = useCurrentUserEmail();

  const updateAssigneesMutation = useUpdateAssignessQuery({ ddqId: ddqId || '', ddqPairId: pair?.id || '' });

  const handleAssigneesChange = (newAssignees: string[]) => {
    if (pair) {
      updateQuestionData(pair.id, { assignees: newAssignees });
    }
    updateAssigneesMutation.mutate({ assigneeIds: newAssignees });
  };

  return (
    <div className='flex max-h-full min-h-full flex-col'>
      <div className='flex items-center px-4 py-2'>
        <div className='flex items-center gap-2 truncate'>
          <h1 className='text-xl font-bold'>Selected Question</h1>
        </div>
        <div className='ml-auto flex items-center gap-2'>
          {pair && (
            <span className='text-muted-foreground shrink-0'>
              {typeof current !== 'undefined' && current !== -1
                ? current + 1
                : '--'}{' '}
              of {length}
            </span>
          )}
          {!pair && <Button className='invisible' />}
          {pair && (
            <Button
              className='px-3'
              variant='outline'
              disabled={prev === undefined}
              onClick={() => {
                captureEvent('analysisClickQuestionArrow', {
                  doc_id: ddqId || '',
                  pair_id: ddqState.selectedQuestion || '',
                  direction: 'prev'
                });

                if (prev !== undefined) setSelectedQuestion(prev);
              }}
            >
              <ArrowLeftIcon />
              <ShortcutKey keys={['p']} />
            </Button>
          )}
          {pair && (
            <Button
              className='px-3'
              variant='outline'
              disabled={next === undefined}
              onClick={() => {
                captureEvent('analysisClickQuestionArrow', {
                  doc_id: ddqId || '',
                  pair_id: ddqState.selectedQuestion || '',
                  direction: 'next'
                });

                if (next !== undefined) setSelectedQuestion(next);
              }}
            >
              <ArrowRightIcon />
              <ShortcutKey keys={['n']} />
            </Button>
          )}
        </div>
      </div>
      <Separator />
      {pair ? (
        <div className='flex flex-1 max-h-full min-h-full flex-col'>
          <div className='flex flex-[1_1_0] items-start p-4 overflow-y-scroll'>
            <div className='flex flex-grow h-full flex-col w-full items-start gap-4 text-sm'>
              <AnswerColumns className='w-full max-h-[1/2] overflow-y-hidden h-fit'>
                <div className='max-h-full overflow-y-scroll'>
                  <Markdown
                    className='markdown-els max-w-6xl mt-2'
                    content={pair.content}
                    ddqId={pair.ddq_id}
                  />
                </div>
                {!isBlank ? <div className='border rounded-lg p-1'>
                  <div className='flex justify-end'>
                    <Assign
                      onSelect={handleAssigneesChange}
                      selectedValues={currentQuestionData?.assignees || []}
                      emailsAndAliases={profiles}
                    />
                  </div>
                  {/* Show current assignees just like in combobox.tsx, dismissable */}
                  <div className='flex flex-col flex-wrap items-end'>
                    {(currentQuestionData?.assignees || []).map(
                      (assignedValue) => {
                        const foundProfile = profiles.find(
                          (p) => p.value === assignedValue
                        );
                        return (
                          <span
                            key={assignedValue}
                            className='flex items-center h-6 px-2 text-xs text-gray-700 bg-gray-100 rounded w-fit m-1'
                          >
                            @{foundProfile?.label ?? assignedValue}
                            <X
                              className='ml-1 h-3 w-3 cursor-pointer'
                              onClick={() => {
                                handleAssigneesChange(
                                  (currentQuestionData?.assignees || []).filter(
                                    (v) => v !== assignedValue
                                  )
                                );
                              }}
                            />
                          </span>
                        );
                      }
                    )}
                  </div>
                </div> : <></>}
              </AnswerColumns>
              {!isBlank && (
                <>
                  <MinimalTiptapEditor
                    className='flex-grow min-h-[40vh]'
                    value={currentQuestionData?.content}
                    onValueChange={onEditorUpdate}
                    ref={editorRef}
                  />
                  <div className='flex w-full justify-between pb-4'>
                    <span>
                      <ShortcutKey keys={['e']} />
                      &nbsp; &nbsp; to focus the editor,{' '}
                      <ShortcutKey keys={['esc']} />
                      &nbsp; &nbsp; to stop.
                    </span>
                    <span className='place-self-end'>
                      {isAnyEditorMutationLoading && (
                        <Loader2
                          className={'inline-block h-4 w-4 animate-spin mr-2'}
                        />
                      )}
                      {currentEditState &&
                        currentEditState.edit_history &&
                        currentEditState.edit_history.length > 0 &&
                        currentEditState.edit_history[0] && (
                          <span
                            title={convertUTCToLocal(
                              currentEditState.edit_history[0].created
                            )}
                          >
                            {'Last edited by ' +
                              displayCurrentUser(
                                currentEditState.edit_history[0].editor.email
                              ) +
                              ' ' +
                              formatFriendlyDate(
                                currentEditState.edit_history[0].created
                              )}
                          </span>
                        )}
                    </span>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      ) : (
        <div className='p-8 text-center text-muted-foreground'>
          No question selected
        </div>
      )}
    </div>
  );
};
